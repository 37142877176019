body {
  background-color: black;
  color: white;
}

.entity {
  position: fixed;
}

.bullet {
  /* background-color: red; */
  /* width: 6px;
  height: 6px; */
  color: red;
}

.lives {
  color:red;
  font-size: 24px;
}

.tPad {
  position: fixed;
  left: 10px;
  top: 10px;
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bPad {
  position: fixed;
  left: 0;
  bottom: 10px;
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.directionPad {
  display: flex;
  flex-direction: row;
}

.directionPad > a,.button{
  margin: 0 10px;
  padding: 10px;
  border-radius: 6px;
  color: red;
  text-decoration: none;
  background-color: white;
}

.blinking{
  animation:blinkingText 1.2s infinite;
}
@keyframes blinkingText{
  0%{     color: white;    }
  49%{    color: white; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: white;    }
}